.container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .modal {
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    // min-width: 400px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.01);
    padding: 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: theme("colors.slate.600");
        padding-left: 0.5rem;
        font-weight: 500;
      }

      .closeBtn {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
