.container {
  position: relative;
  margin-left: auto;
  display: flex;

  .profile {
    border: 2px solid theme("colors.slate.200");
    border-right: 0;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.4rem;
    color: theme("colors.slate.400");
    cursor: pointer;

    &:hover {
      color: theme("colors.sky.300");
      background-color: theme("colors.sky.50");
      border: 2px solid theme("colors.sky.100");
      border-right: 0;
      box-shadow: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .profile:hover ~ .button {
    border-left: 2px solid theme("colors.sky.100");
  }

  .button {
    font-size: 14px;
    width: 150px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    @media (max-width: 381px) {
      width: 70px;

      img {
        display: none;
      }
    }

    p {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    img {
      height: 18px;
    }
  }

  .showProfile {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .options {
    position: absolute;
    top: calc(100% + 6px);
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 23px 60px rgba(0, 89, 176, 0.05);
    border: 1px solid theme("colors.slate.100");
    overflow: hidden;
    padding: 0.5rem;

    li {
      color: theme("colors.slate.500");
      padding: 6px 12px;
      cursor: pointer;
      transition: all 0.1s;
      border-radius: 0.5rem;

      &:hover {
        background-color: theme("colors.sky.50");
        color: theme("colors.sky.500");
      }

      &.active {
        background-color: theme("colors.sky.50");
        color: theme("colors.sky.500");
      }
    }
  }
}
