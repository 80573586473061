.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  .walletModal {
    background: #fff;
    padding: 2rem;
    box-shadow: 0 4px 30px 0 theme("colors.slate.100");
    border-radius: 1rem;

    svg {
      margin: auto;
    }

    .title {
      font-size: 2rem;
      color: theme("colors.slate.700");
      font-weight: 500;
      text-align: center;
    }
  }
}

.desc {
  color: theme("colors.slate.400");
  font-weight: 300;
  text-align: center;
}

.wallets {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 0;
  transition: all;

  @media (max-width: 385px) {
    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    gap: 18px;
    border: 2px solid theme("colors.slate.100");
    border-radius: 12px;
    padding: 12px 24px;
    min-width: 300px;
    font-size: 14px;
    font-weight: 500;
    color: theme("colors.slate.600");
    cursor: pointer;
    transition: all 0.2s;

    @media (max-width: 385px) {
      min-width: 100%;
    }

    &:hover {
      border: 2px solid theme("colors.slate.200");
      background-color: theme("colors.slate.100");
    }

    img {
      height: 24px;
    }
  }
}
