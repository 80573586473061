.breadcrumb {
  ul,
  div {
    display: flex;
    gap: 5px;
    margin: 0;
    padding-left: 0px;
    align-items: center;
  }
  li {
    font-size: 18px;
    color: #94a3b8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
      color: #0074e5;
    }
  }
  span svg {
    width: 18px;
    height: 18px;
    color: #cbd5e1;
  }
  li:last-child {
    color: rgba(0, 0, 0, 0.88);
    font-size: 18px;
  }
  li:last-child:hover {
    cursor: unset;
    color: rgba(0, 0, 0, 0.88);
  }
}
