.body {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    background-color: theme("colors.sky.50");
    color: theme("colors.sky.500");
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    margin: 0 0.25rem;
  }

  .desc,
  .newCredit {
    text-align: center;
    color: theme("colors.slate.400");
    line-height: 170%;
  }
}
