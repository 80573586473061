.item {
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.25rem 10px theme("colors.slate.100");
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  overflow: hidden;

  &:hover .messageContainer {
    transform: translateY(0);
    opacity: 1;
  }

  .messageContainer {
    position: absolute;
    z-index: 2;
    color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    transform: translateY(-100%);
    transition: 0.3s;
    opacity: 0;

    &.ok {
      background: theme("colors.green.500");
    }

    &.error {
      background: theme("colors.red.500");
    }

    &.pending {
      background: theme("colors.yellow.500");
    }
  }

  .icon {
    background-color: theme("colors.sky.50");
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: relative;

    & > svg {
      width: 24px;
      height: 24px;
      color: theme("colors.sky.500");
    }

    span {
      padding: 0.15rem;
      border-radius: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(10%, 10%);

      &.ok {
        background-color: theme("colors.green.500");
      }

      &.error {
        background-color: theme("colors.red.500");
      }

      &.pending {
        background-color: theme("colors.yellow.500");
      }

      & > svg {
        width: 12px;
        height: 12px;
        color: #fff;
      }
    }
  }

  .price {
    margin-left: auto;
    font-size: 1.1rem;
    color: theme("colors.slate.700");
    white-space: nowrap;

    span {
      font-size: 0.8rem;
      color: theme("colors.slate.400");
    }
  }

  .title {
    .name {
      font-size: 1.1rem;
      color: theme("colors.slate.700");
    }
    .date {
      font-size: 0.8rem;
      color: theme("colors.slate.400");
    }
  }
}
