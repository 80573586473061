.syncAvatar {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.email {
  &.ok {
    svg {
      color: #28c76f;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    color: theme("colors.red.500");
  }
}

// .profile,
// .activity {
//   width: 50%;

//   &.activity {
//     margin-top: 2rem;
//   }
//   @media (max-width: 1240px) {
//     width: 100%;

//     &.activity {
//       margin-left: 3rem;
//     }
//   }
// }
