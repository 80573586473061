.options {
  position: absolute;
  top: calc(100% + 6px);
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 23px 60px rgba(0, 89, 176, 0.08);
  border: 1px solid theme("colors.slate.100");
  overflow: hidden;
  padding: 0.5rem;
  z-index: 100;
  text-align: left;

  li {
    color: theme("colors.slate.500");
    padding: 6px 12px;
    cursor: pointer;
    transition: all 0.1s;
    border-radius: 0.5rem;
    white-space: nowrap;

    &:hover {
      background-color: theme("colors.sky.50");
      color: theme("colors.sky.500");
    }

    &.active {
      background-color: theme("colors.sky.50");
      color: theme("colors.sky.500");
    }
  }
}
